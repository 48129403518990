module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#2d2d2d","cache_busting_mode":"none","description":"Brico Dépot","display":"fullscreen","icon":"src/images/app/logo-512.png","icons":[{"sizes":"48x48","src":"/favicons/icon-48x48.png","type":"image/png"},{"sizes":"72x72","src":"/favicons/icon-72x72.png","type":"image/png"},{"sizes":"96x96","src":"/favicons/icon-96x96.png","type":"image/png"},{"sizes":"144x144","src":"/favicons/icon-144x144.png","type":"image/png"},{"sizes":"192x192","src":"/favicons/icon-192x192.png","type":"image/png"},{"sizes":"256x256","src":"/favicons/icon-256x256.png","type":"image/png"},{"sizes":"384x384","src":"/favicons/icon-384x384.png","type":"image/png"},{"sizes":"512x512","src":"/favicons/icon-512x512.png","type":"image/png"}],"lang":"fr","name":"Brico Dépot","short_name":"Brico Dépot","start_url":"/?source=pwa","theme_color":"#2d2d2d","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/grid/","/cuisines/**/*"],"workboxConfig":{"cacheId":"test.0.0.1","cleanupOutdatedCaches":true,"globPatterns":["**/*.{jpg,jpeg,png,svg,webp,ttf,woff,woff2}"],"runtimeCaching":[{"handler":"CacheFirst","urlPattern":{}}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
