/* eslint-disable no-underscore-dangle */
/* eslint-disable no-alert */
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@reach/dialog/styles.css';

import './src/styles/vendors/sanitize.styl';
import './src/styles/vendors/reset.styl';
import './src/styles/global.styl';
import './src/fonts/style.styl';

// export const onServiceWorkerUpdateReady = () => window.location.reload(true);

export const onServiceWorkerUpdateFound = () => {
    const answer = window.confirm(
        `Une nouvelle version de l'application est disponible (actuelle : ${process.env.__VERSION__})` +
            `Relancer pour voir la dernière version ?`,
    );
    if (answer === true) {
        window.location.reload();
    }
};

export const onServiceWorkerUpdateReady = (
    apiCallbackContext,
    pluginOptions,
) => {
    console.log('yeah Service Worker Updated ! ');

    console.log('pluginOptions', pluginOptions);
    // const isBrowser = () => typeof window !== 'undefined';
};

export const onServiceWorkerInstalled = (apiCallbackContext, pluginOptions) => {
    console.log('yeah Service Worker Installed ! ');

    console.log('pluginOptions', pluginOptions);
};

export const onClientEntry = () => {
    // Without this function body the import will not be picked up.
    if (!(`IntersectionObserver` in window)) {
        // eslint-disable-next-line no-unused-expressions
        import(`intersection-observer`);
        console.log(`# IntersectionObserver is polyfilled!`);
    }
};
