// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-grid-js": () => import("./../../../src/pages/grid.js" /* webpackChunkName: "component---src-pages-grid-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-views-templates-kitchen-item-color-js": () => import("./../../../src/views/templates/KitchenItemColor.js" /* webpackChunkName: "component---src-views-templates-kitchen-item-color-js" */),
  "component---src-views-templates-kitchen-item-js": () => import("./../../../src/views/templates/KitchenItem.js" /* webpackChunkName: "component---src-views-templates-kitchen-item-js" */)
}

